.logo-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.logo-div {
  width: 100px;
  height: 50px;
}
@media screen and (max-width: 768px) {
  .logo-div {
    width: 50px;
    height: 40px;
    /* object-fit: cover; */
  }
}
